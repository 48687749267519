import React, { useState } from "react";
import Image from "next/image";
import Flag from "../../utils/Flag";
import Link from "next/link";
import HeaderWrapper from "./Wrapper";

const items = [
  {
    label: "Odzież",
    link: "/odziez",
    image: "/230110/mega.jpg",
    submenu: [
      {
        label: "Legginsy sportowe",
        link: "/legginsy-sportowe",
        description:
          "Wygodne, bezszwowe legginsy podkreślające piękno kobiecej sylwetki. ",
      },
      {
        label: "Koszulki treningowe",
        link: "/koszulki-treningowe",
        description: "Doskonale dopasowane longsleeve i topy sportowe.",
      },
      {
        label: "Spodenki sportowe",
        link: "/spodenki-sportowe",
        description:
          "Kolarki bezszwowe to istna rewolucja gatunku. Wygodny, elastyczny materiał maskujący niedoskonałości.",
      },
      {
        label: "Staniki sportowe",
        link: "/staniki-sportowe",
        description:
          "Odkryj biustonosze z oddychającego materiału, stworzone w trosce o Twój komfort.",
      },
      {
        label: "Bluzy sportowe",
        link: "/bluzy-sportowe",
        description:
          "Bluzy, które sprawdzą się zarówno podczas treningu, jak i w domowym zaciszu.",
      },
      {
        label: "Spodnie sportowe",
        link: "/spodnie-sportowe",
        description:
          "Nasze spodnie dresowe to połączenie wygody w najlepszym wydaniu i modnego stylu casualowego. ",
      },
    ],
  },
  // {
  //   label: 'Akcesoria',
  //   link: '/akcesoria',
  //   image: '/mega-2.jpg',
  // submenu: [
  //   {
  //     label: 'Shaker',
  //     link: '/shaker',
  //     description: 'Wyprodukowane z miłością do każdej z Was :)',
  //   },
  //   {
  //     label: 'Kubki',
  //     link: '/kubki',
  //     description: 'Te szorty są absolutną rewolucją w swoim gatunku!',
  //   },
  //   {
  //     label: 'Czapki',
  //     link: '/czapki',
  //     description:
  //       'Ten top to odpowiedź dla wymagających sportowców. Zapewnia solidne wsparcie.',
  //   },
  //   {
  //     label: 'Skarpetki',
  //     link: '/skarpetki',
  //     description:
  //       'W tym lekkim, dresowym materiale i klasycznym kroju poczujesz się.',
  //   },
  //   {
  //     label: 'Maty',
  //     link: '/maty',
  //     description:
  //       'W nich podbijesz świat z kanapy, ululasz dzieciątko, upieczesz ciasto.',
  //   },
  //   {
  //     label: 'Butelka termiczna',
  //     link: '/butelka-termiczna',
  //     description:
  //       'Biker szorty sportowe to absolutny Must Have w sportowej garderobie każdej.',
  //   },
  // ],
  // },
  { label: "Nowości", link: "/nowa-kolekcja" },
  { label: "Akcesoria", link: "/akcesoria" },
  { label: "Zestawy", link: "/zestawy" },
  { label: "Training club", link: "/gh-club" },
  { label: "GH Camp", link: "/hero-camp" },
  { label: "Sale out", link: "/sale-out" },
  // { label: "Blog", link: "/blog" },
];

const Header: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(-1);
  return (
    <>
      {
        /* <div className='bg-white'>
        <div className='container mx-auto py-2 px-4'>
          <div className='grid grid-cols-3 gap-4'>
            <div className='text-left font-light text-white tracking-wide'></div>
            <div className='text-center text-white font-light tracking-wide'></div>
            <div className='flex justify-end space-x-4'>
              <span>
                <Flag code='pl' width={20} />
              </span>
              <span>
                <Flag code='us' width={20} />
              </span>
            </div>
          </div>
        </div>
      </div> */
      }
      <HeaderWrapper
        isCollapsed={isCollapsed}
        onHamburgerClick={() => setIsCollapsed(!isCollapsed)}
        heightClasses="h-16 lg:h-20"
        wrapperClasses="px-0 sm:px-4"
        marginClass="mt-9"
      >
        <div
          className="text-center lg:text-left flex-1 lg:flex-none"
          style={{ lineHeight: 0 }}
        >
          <Link href="/">
            <a>
              <span className="hidden md:block">
                <div className="px-4">
                  <Image src="/gh.svg" alt="GymHero" width={70} height={70} />
                </div>
              </span>
              <span className="block md:hidden">
                <Image src="/gh.svg" width={48} height={48} alt="GymHero" />
              </span>
            </a>
          </Link>
        </div>
        <div
          className={`${
            isCollapsed ? "block inset-0" : "hidden"
          } lg:block lg:flex-1 absolute lg:static top-28`}
        >
          <div className="lg:space-x-5 lg:ml-10">
            {items.map((item, index) => (
              <div className="block lg:inline-block hover-trigger" key={index}>
                <div className="lg:h-20 lg:flex items-center font-bebas">
                  {item.submenu
                    ? (
                      <span
                        onClick={() => setActiveSubmenu(index)}
                        className="block lg:hidden cursor-pointer py-2 px-4 border-b lg:py-0 lg:px-0 lg:border-0 text-lg font-normal text-gray-600 hover:text-gray-800 uppercase tracking-wider"
                      >
                        {item.label}
                      </span>
                    )
                    : null}
                  <Link key={index} href={item.link}>
                    <a
                      onClick={() =>
                        setIsCollapsed(false)}
                      className={`${
                        item.submenu ? "hidden" : "block"
                      } lg:inline py-2 px-4 border-b lg:py-0 lg:px-0 lg:border-0 text-lg  ${
                        index === items.length - 1
                          ? "font-bold text-red-500 hover:text-red-600"
                          : "text-gray-600 hover:text-gray-800 font-normal"
                      } uppercase tracking-wider`}
                    >
                      {item.label}
                    </a>
                  </Link>
                </div>
                {item.submenu
                  ? (
                    <div
                      className={`fixed top-20 left-0 right-0 lg:border-t bg-white ${
                        activeSubmenu === index ? "block" : "hidden"
                      } lg:hover-target lg:shadow-2xl`}
                    >
                      <div className="container mx-auto py-8 lg:py-16 lg:px-4 text-gray-600">
                        <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-8 lg:gap-y-4">
                          <div className="grid grid-cols-1 lg:grid-cols-2">
                            <span
                              onClick={() => setActiveSubmenu(-1)}
                              className="block font-bebas lg:hidden cursor-pointer py-2 px-4 border-b lg:py-0 lg:px-0 lg:border-0 text-lg font-normal text-black uppercase tracking-wider"
                            >
                              Wróć
                            </span>
                            <Link href={item.link} key={item.link}>
                              <a
                                key={item.link}
                                onClick={() =>
                                  setIsCollapsed(false)}
                                className="block font-bebas lg:hidden cursor-pointer py-2 px-4 border-b lg:py-0 lg:px-0 lg:border-0 text-lg font-normal text-black uppercase tracking-wider"
                              >
                                Pokaż wszystko
                              </a>
                            </Link>
                            {item.submenu.map((item) => (
                              <a
                                key={item.link}
                                href={item.link}
                                className="text-lg block py-2 px-4 lg:px-0 lg:py-0 border-b lg:border-b-0"
                              >
                                <span className="font-bebas">{item.label}</span>
                                <br />
                                <span className="text-sm hidden lg:inline">
                                  {item.description}
                                </span>
                              </a>
                            ))}
                          </div>

                          <div className="hidden lg:block">
                            <Image src={item.image} width={640} height={372} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                  : null}
              </div>
            ))}
            <Link href="/wishlist">
              <a
                onClick={() => setIsCollapsed(false)}
                className={`block lg:hidden py-2 px-4 border-b lg:py-0 lg:px-0 lg:border-0 text-lg font-normal text-gray-600 hover:text-gray-800 uppercase tracking-wider`}
              >
                <span className="font-bebas">Lista życzeń</span>
              </a>
            </Link>
            <Link href="/customer">
              <a
                onClick={() => setIsCollapsed(false)}
                className={`block lg:hidden py-2 px-4 border-b lg:py-0 lg:px-0 lg:border-0 text-lg font-normal text-gray-600 hover:text-gray-800 uppercase tracking-wider`}
              >
                <span className="font-bebas">Moje konto</span>
              </a>
            </Link>
          </div>
        </div>
      </HeaderWrapper>
    </>
  );
};

export default Header;
