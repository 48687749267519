import Link from 'next/link';
import React from 'react';
import { useCart } from '@wk93/socommerce-sdk';
import Icon from '../utils/Icon';

const CartIcon: React.FC = () => {
  const { cart, isLoading } = useCart();

  return (
    <Link href='/cart'>
      <a className='relative inline-block'>
        <Icon type='cart' className='text-gray-600 h-6' />
        {!isLoading && cart.totalQuantity > 0 ? (
          <span
            className={`absolute top-0 right-0 inline-flex items-center  justify-center font-mono py-1 w-5 text-xs leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-orange-400 rounded-full`}
          >
            {cart.totalQuantity}
          </span>
        ) : null}
      </a>
    </Link>
  );
};

export default CartIcon;
