import React, { useEffect, useRef, useState } from 'react';

interface Props {
  sections: { title: string; content: JSX.Element }[];
  defaultActive?: number;
}

const Collapse: React.FC<Props> = ({ sections, defaultActive = 0 }) => {
  const [active, setActive] = useState(defaultActive);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <section ref={ref} className='border-t'>
      {sections.map((section, index) => (
        <article key={index} className='border-b'>
          <div className='bg-grey-lightest border-indigo'>
            <header
              className='flex justify-between items-center py-2 md:py-5  select-none cursor-pointer'
              onClick={() => {
                let navbarHeight = window.innerWidth > 1280 ? 80 : 64;

                setActive(index === active ? -1 : index);
                window.scrollTo({
                  top: ref.current.offsetTop - navbarHeight,
                });
              }}
            >
              <span className='text-indigo font-thin md:text-xl'>
                {section.title}
              </span>
              <div className='rounded-full border  border-indigo w-7 h-7 flex items-center justify-center bg-indigo'>
                {active === index ? '-' : '+'}
              </div>
            </header>
            {active === index ? (
              <div className='pb-5 text-grey-darkest font-light text-sm md:text-base'>
                {section.content}
              </div>
            ) : null}
          </div>
        </article>
      ))}
    </section>
  );
};

export default Collapse;
