import { useRouter } from 'next/dist/client/router';
import React, { useEffect, useRef } from 'react';
import LoadingIcon from '../utils/LoadingIcon';
import ListItems from './ListItems';
import { useSearch } from '@wk93/socommerce-sdk';

interface Props {
  onClose: () => void;
}

const Search: React.FC<Props> = ({ onClose }) => {
  const { value, onChange, isLoaded, count, products } = useSearch();
  const ref = useRef<HTMLInputElement>(null);
  const router = useRouter();

  const escFunction = (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    if (ref) {
      ref.current.focus();
    }

    if (document) {
      document.addEventListener('keydown', escFunction, false);
    }

    return () => document.removeEventListener('keydown', escFunction, false);
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (isLoaded && count > 0) {
          onClose();
          router
            .push(`/search/${encodeURIComponent(value)}`)
            .then(() => window.scrollTo(0, 0));
        }
      }}
    >
      <div className={`fixed z-50 inset-0 overflow-y-auto`}>
        <div className='flex items-end justify-center min-h-screen text-center sm:block sm:p-0'>
          <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
            <div
              onClick={onClose}
              className='absolute inset-0 bg-gray-500 opacity-75'
            ></div>
            <div
              className={`inline-block align-bottom bg-white sm:rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-40 sm:align-middle sm:max-w-lg w-full`}
              role='dialog'
              aria-modal='true'
              aria-labelledby='modal-headline'
            >
              <div className='relative text-gray-600 focus-within:text-gray-400'>
                <span className='absolute inset-y-0 right-10 flex items-center pl-2'>
                  {isLoaded ? (
                    <button
                      type='submit'
                      className='p-1 focus:outline-none focus:shadow-outline'
                    >
                      <svg
                        fill='none'
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        viewBox='0 0 24 24'
                        className='w-6 h-6'
                      >
                        <path d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'></path>
                      </svg>
                    </button>
                  ) : (
                    <LoadingIcon className='w-6 h-6 mr-2' />
                  )}
                </span>

                <span
                  className='cursor-pointer absolute inset-y-0 right-2 flex items-center pl-2'
                  onClick={onClose}
                >
                  <svg
                    className='w-6 h-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </span>
                <input
                  ref={ref}
                  placeholder='Szukaj...'
                  className='w-full text-lg px-2 h-12 outline-none'
                  type='text'
                  value={value}
                  onChange={(e) => onChange(e.currentTarget.value)}
                />
              </div>

              {isLoaded ? (
                value.length > 0 && count > 0 ? (
                  <>
                    <ListItems onClick={() => onClose()} products={products} />

                    <button
                      type='submit'
                      className='border-t block w-full p-2 text-center text-orange-400'
                    >
                      Zobacz wszystkie ({count})
                    </button>
                  </>
                ) : null
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Search;
