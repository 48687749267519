import React from 'react';

interface Props {
  active: boolean;
  onClick: () => void;
}

const Hamburger: React.FC<Props> = ({ active, onClick }) => (
  <div className='col-span-2 pl-2 sm:pl-0 sm:-ml-2 lg:hidden flex items-center'>
    <button
      className={`hamburger hamburger--spring ${active ? 'is-active' : ''}`}
      type='button'
      onClick={onClick}
    >
      <span className='hamburger-box'>
        <span className='hamburger-inner'></span>
      </span>
    </button>
  </div>
);

export default Hamburger;
