import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import CartIcon from '../../cart/CartIcon';
import Search from '../../search';
import Icon from '../../utils/Icon';
import WishlistIcon from '../../wishlist/WishlistIcon';

interface Props {
  color: string;
}

const Icons: React.FC<Props> = ({ color }) => {
  const [showSearch, setShowSearch] = useState(false);

  const escFunction = (e) => {
    if (e.metaKey && e.key === 'k') {
      setShowSearch(!showSearch);
    }
  };

  useEffect(() => {
    if (document) {
      document.addEventListener('keydown', escFunction, false);
    }

    return () => document.removeEventListener('keydown', escFunction, false);
  }, []);

  return (
    <div className='col-span-2 lg:col-span-2 w-10 lg:w-auto'>
      {showSearch ? <Search onClose={() => setShowSearch(false)} /> : null}
      <div className='flex justify-items-end space-x-3 justify-end'>
        <div className='flex justify-items-end space-x-3 justify-end'>
          <span className='cursor-pointer' onClick={() => setShowSearch(true)}>
            <Icon type='search' className=' text-gray-600 h-6' />
          </span>
          <CartIcon />
          <Link href='/customer'>
            <a>
              <Icon type='user' className='hidden lg:block text-gray-600 h-6' />
            </a>
          </Link>
          <WishlistIcon className='hidden lg:block' />
        </div>

        {/* <Icon type='cart' className={`text-${color} h-6`} />
      <Icon type='search' className={`hidden lg:block text-${color} h-6`} />
      <Icon type='user' className={`hidden lg:block text-${color} h-6`} /> */}
      </div>
    </div>
  );
};
export default Icons;
