import React from "react";

// https://icons8.com/icon/pack/logos/metro
type Social = "facebook" | "instagram" | "pinterest" | "tiktok";
interface Props {
  type: Social;
  fill?: string;
  height?: number;
}
const SocialIcon: React.FC<Props> = ({ type, fill, height }) => {
  const types = {
    pinterest:
      "M13,0C5.8210001,0,0,5.8210001,0,13c0,5.5074997,3.4265003,10.2115002,8.2624998,12.1049995	c-0.1134996-1.0279999-0.2165003-2.6065006,0.0454998-3.7290001c0.2360001-1.0139999,1.5244999-6.4619999,1.5244999-6.4619999	s-0.3889999-0.7789993-0.3889999-1.9294996c0-1.8079996,1.0474997-3.1570005,2.3520002-3.1570005	c1.1094999,0,1.6445007,0.8330002,1.6445007,1.8310003c0,1.1160002-0.7095003,2.783-1.0769997,4.3280001	c-0.3065004,1.2945004,0.6490002,2.3500004,1.9249992,2.3500004c2.3104992,0,4.0865002-2.4365005,4.0865002-5.9530001	c0-3.1125002-2.2360001-5.2890005-5.4295006-5.2890005c-3.6984997,0-5.8695002,2.7744999-5.8695002,5.6420002	c0,1.1170006,0.4300003,2.3150005,0.9674997,2.9669991c0.1059999,0.1289997,0.1215,0.2415009,0.0900002,0.3724995	c-0.0985003,0.4104996-0.3175001,1.2935009-0.3610001,1.4745007c-0.0565004,0.2385006-0.1885004,0.2880001-0.4344997,0.1735001	c-1.6239996-0.7560005-2.6389999-3.1294994-2.6389999-5.0365c0-4.1005001,2.9790001-7.8670001,8.5900002-7.8670001	c4.5100002,0,8.0144997,3.2129998,8.0144997,7.5084996c0,4.4804993-2.8255005,8.0860004-6.7460003,8.0860004	c-1.3174992,0-2.5555-0.6844997-2.9790001-1.493c0,0-0.6520004,2.4820004-0.8105001,3.0904999	c-0.2934999,1.1289997-1.0854998,2.5450001-1.6164999,3.407999C10.3675003,25.7964993,11.6599998,26,13,26	c7.1790009,0,13-5.8209991,13-13S20.1795006,0,13,0z",
    tiktok:
      "M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z",
    facebook:
      "M 21.125 0 L 4.875 0 C 2.183594 0 0 2.183594 0 4.875 L 0 21.125 C 0 23.816406 2.183594 26 4.875 26 L 21.125 26 C 23.816406 26 26 23.816406 26 21.125 L 26 4.875 C 26 2.183594 23.816406 0 21.125 0 Z M 20.464844 14.003906 L 18.03125 14.003906 L 18.03125 23.007813 L 13.96875 23.007813 L 13.96875 14.003906 L 12.390625 14.003906 L 12.390625 10.96875 L 13.96875 10.96875 L 13.96875 9.035156 C 13.96875 6.503906 15.019531 5 18.007813 5 L 21.03125 5 L 21.03125 8.023438 L 19.273438 8.023438 C 18.113281 8.023438 18.035156 8.453125 18.035156 9.265625 L 18.03125 10.96875 L 20.796875 10.96875 Z",
    instagram:
      "M 7.546875 0 C 3.390625 0 0 3.390625 0 7.546875 L 0 18.453125 C 0 22.609375 3.390625 26 7.546875 26 L 18.453125 26 C 22.609375 26 26 22.609375 26 18.453125 L 26 7.546875 C 26 3.390625 22.609375 0 18.453125 0 Z M 7.546875 2 L 18.453125 2 C 21.527344 2 24 4.46875 24 7.546875 L 24 18.453125 C 24 21.527344 21.53125 24 18.453125 24 L 7.546875 24 C 4.472656 24 2 21.53125 2 18.453125 L 2 7.546875 C 2 4.472656 4.46875 2 7.546875 2 Z M 20.5 4 C 19.671875 4 19 4.671875 19 5.5 C 19 6.328125 19.671875 7 20.5 7 C 21.328125 7 22 6.328125 22 5.5 C 22 4.671875 21.328125 4 20.5 4 Z M 13 6 C 9.144531 6 6 9.144531 6 13 C 6 16.855469 9.144531 20 13 20 C 16.855469 20 20 16.855469 20 13 C 20 9.144531 16.855469 6 13 6 Z M 13 8 C 15.773438 8 18 10.226563 18 13 C 18 15.773438 15.773438 18 13 18 C 10.226563 18 8 15.773438 8 13 C 8 10.226563 10.226563 8 13 8 Z",
  } as Record<Social, string>;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={type === "tiktok" ? "0 0 2859 3333" : "0 0 26 26"}
      width={`${height ? height : 26}px`}
      height={`${height ? height : 26}px`}
      fill={fill}
    >
      <path d={types[type]} />
    </svg>
  );
};

export default SocialIcon;
