import React, { useState } from 'react';
import Hamburger from './Hamburger';
import Icons from './Icons';
import SearchInput from './SearchInput';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

interface Props {
  isCollapsed: boolean;
  onHamburgerClick: () => void;
  heightClasses: string;
  wrapperClasses?: string;
  marginClass?: string;
}

const HeaderWrapper: React.FC<Props> = ({
  isCollapsed,
  onHamburgerClick,
  children,
  heightClasses,
  wrapperClasses,
  marginClass,
}) => {
  const [margin, setMargin] = useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    // if (currPos.y > -36) {
    //   setMargin(true);
    // } else {
    //   setMargin(false);
    // }
  });

  return (
    <>
      <div
        className={`${isCollapsed ? 'inset-0' : 'inset-x-0'} ${
          margin && !isCollapsed ? 'static' : 'fixed'
        }  z-40 l-0 bg-white lg:inset-x-0 top-0`}
      >
        <div
          className={`container mx-auto flex items-center ${heightClasses} ${wrapperClasses}`}
        >
          <div className='w-full'>
            <div className='flex items-center content-center lg:px-0'>
              <Hamburger active={isCollapsed} onClick={onHamburgerClick} />
              {children}
              <Icons color='gray-400' />
            </div>
          </div>
        </div>
      </div>
      {!margin ? <div className={heightClasses}></div> : null}
    </>
  );
};

export default HeaderWrapper;
