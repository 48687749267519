import React from "react";
import Footer from "./footer";
import Header from "./header/Header";

interface Props {
  withFooter?: boolean;
}

const Layout: React.FC<Props> = ({ children, withFooter = true }) => (
  <>
    <Header />
    {children}
    {withFooter ? <Footer /> : null}
  </>
);

export default Layout;
