import { useWishlist } from '@wk93/socommerce-sdk';
import Link from 'next/link';
import React from 'react';
import Icon from '../utils/Icon';

interface Props {
  className: string;
}

const WishlistIcon: React.FC<Props> = ({ className }) => {
  const { items } = useWishlist();

  return (
    <Link href='/wishlist'>
      <a className={`relative inline-block ${className}`}>
        <Icon type='heart' className='text-gray-600 h-6' />
        {items.length > 0 ? (
          <span
            className={`absolute top-0 right-0 inline-flex items-center  justify-center font-mono py-1 w-5 text-xs leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-orange-400 rounded-full`}
          >
            {items.length}
          </span>
        ) : null}
      </a>
    </Link>
  );
};

export default WishlistIcon;
