import Image from "next/image";
import Link from "next/link";
import React from "react";
import Collapse from "../../product/collapse";
import SocialIcon from "../../utils/SocialIcon";

const items = [
  {
    title: "Nasza firma",
    links: [
      {
        href: "/content7-o-firmie",
        name: "O firmie",
      },
      {
        href: "/content8-kontakt",
        name: "Kontakt",
      },
      {
        href: "/content-wspolpraca",
        name: "Współpraca",
      },
      {
        href: "https://club.gymhero.eu",
        name: "Gym Hero Training Club",
      },
    ],
  },

  {
    title: "Obsługa klienta",
    links: [
      // {
      //   href: '/platnosci',
      //   name: 'Płatności',
      // },
      {
        href: "/content21-dostawa",
        name: "Dostawa",
      },
      {
        href: "/content10-zwroty-wymiany-reklamacje",
        name: "Zwroty, wymiany, reklamacje",
      },
      {
        href: "/wysylka-zagraniczna",
        name: "Wysyłka zagraniczna",
      },
    ],
  },
  {
    title: "Informacje",
    links: [
      {
        href: "/regulamin",
        name: "Regulamin",
      },
      {
        href: "/polityka-prywatnosci",
        name: "Polityka prywatności",
      },
      {
        href: "/program-lojalnosciowy",
        name: "Program lojalnościowy",
      },
      // {
      //   href: '/content16-prawa-autorskie',
      //   name: 'Prawa autorskie',
      // },
      // {
      //   href: '/content20-regulaminy-promocji',
      //   name: 'Regulaminy promocji',
      // },
    ],
  },
  {
    title: "Moje konto",
    links: [
      {
        href: "/customer",
        name: "Dane osobowe",
      },
      {
        href: "/customer",
        name: "Historia zakupów",
      },
      {
        href: "/view-order",
        name: "Złóż reklamację",
      },
      {
        href: "/view-order",
        name: "Zwróć zamówienie",
      },
    ],
  },
];

const Footer: React.FC = () => (
  <div className="border-black border-opacity-5 bg-gray-50 md:border-t">
    <div className="container mx-auto px-4 text-sm">
      <div className="px-4 lg:px-0 ">
        <div className="">
          <div className="block sm:hidden">
            <Collapse
              defaultActive={-1}
              sections={items.map((item) => ({
                title: item.title,
                content: (
                  <>
                    <ul className="font-light text-gray-500">
                      {item.links.map((link) => (
                        <li key={link.href}>
                          <Link href={link.href}>
                            <a>{link.name}</a>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </>
                ),
              }))}
            />
          </div>
          <div className="hidden sm:block">
            <div className="grid grid-cols-4 gap-4 text-center">
              {items.map((item, index) => (
                <div key={index} className="col-span-4 sm:col-span-1 md:py-5">
                  <div className="pb-4 font-medium uppercase text-gray-600">
                    {item.title}
                  </div>
                  <ul className="font-light text-gray-500">
                    {item.links.map((link) => (
                      <li key={`${link.href}-${link.name}`}>
                        <Link href={link.href}>
                          <a>{link.name}</a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container mx-auto text-sm">
      <div className="border-black border-opacity-5 pt-4 pb-8 px-8 md:px-4 text-center text-gray-500 sm:border-t">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="flex items-center justify-center md:justify-start space-x-3 pb-4 md:pb-0">
            {[
              {
                link: "https://www.facebook.com/gymhero.eu",
                icon: "facebook",
                name: "GYMHERO.EU",
              },
              {
                link: "https://www.instagram.com/gymhero_girls",
                icon: "instagram",
                name: "gymhero_girls",
              },
              {
                link: "https://pl.pinterest.com/1lhbt6gg1ltyl8ccqeqh0yy7r1jinh",
                icon: "pinterest",
                name: "Gymhero",
              },
              {
                link: "https://www.tiktok.com/@gymhero_girls",
                icon: "tiktok",
                name: "gymhero_girls",
              },
            ].map((item) => (
              <a
                key={item.link}
                rel="nofollow noreferrer"
                target="_blank"
                className="cursor-pointer"
                href={item.link}
              >
                <SocialIcon
                  type={item.icon as any}
                  height={20}
                  fill="rgb(240,168,185)"
                />
              </a>
            ))}
          </div>
          <div className="flex items-center justify-center md:justify-end space-x-3">
            <a href="https://socommerce.pl" target="_blank" rel="noreferrer">
              <Image
                src="https://socommerce.b-cdn.net/logo_black.svg"
                alt="Socommerce"
                width="110"
                height="30"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
