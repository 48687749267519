import Link from 'next/link';
import React from 'react';
import { CollectionProduct } from '@wk93/socommerce-sdk';
interface Props {
  products: CollectionProduct[];
  onClick: () => void;
}

const ListItems: React.FC<Props> = ({ products, onClick }) => (
  <>
    {products.map((product) => (
      <Link href={`/${product.link.slug}`} key={product.link.slug}>
        <a onClick={onClick}>
          <div className='p-2 flex items-center cursor-pointer border-t'>
            <img src={product.media[0]} style={{ height: '75px' }} />
            <div className='flex-1 px-4'>{product.name}</div>
            <div className='text-gray-400'>
              {(product.discountPrice
                ? product.discountPrice.brutto
                : product.price.brutto
              ).toLocaleString('pl-PL', {
                style: 'currency',
                currency: 'PLN',
              })}
            </div>
          </div>
        </a>
      </Link>
    ))}
  </>
);

export default ListItems;
